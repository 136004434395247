export enum URL_SEARCH_PARAMS {
  NAME = "name",
}

export const LANGUAGES = [
  {
    label: "Dutch",
    locale: "nl-BE",
    isIndented: true,
  },

  {
    label: "French",
    locale: "fr-BE",
    isIndented: true,
  },
  {
    label: "United Kingdom",
    locale: "uk",
  },
  {
    label: "Netherlands",
    locale: "nl",
  },
];
