import { navigate } from "gatsby";

import { useEffect } from "react";
import ThankYouSection from "@/components/sections/ThankYouSection";
import SimplifiedLayout from "@/components/layout/SimplifiedLayout";
import useQueryParams from "@/base/hooks/useQueryParams";
import { URL_SEARCH_PARAMS } from "@/base/helpers/consts";

function ThankYouPage() {
  const seo = { title: "Thank you Page" };
  const name = useQueryParams(URL_SEARCH_PARAMS.NAME);
  useEffect(() => {
    if (!name) {
      navigate("/");
    }
  }, []);
  return (
    <SimplifiedLayout seo={seo} type="website">
      <ThankYouSection name={name} />
    </SimplifiedLayout>
  );
}

export default ThankYouPage;
