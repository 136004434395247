import clsx from "clsx";

import { plausibleTrack } from "@/base/utils/plausible";
import Icon from "@/components/elements/Icon";
import * as styles from "./styles.module.scss";
import { ButtonProps } from "./types";

const icons = {
  link: <Icon name="arrowUpRight" />,
  download: <Icon name="download" />,
  newsletter: <Icon name="newsletter" />,
};

export function Button({
  children,
  variant,
  type = "button",
  className = "",
  icon,
  isIconLeft,
  dataAnalytics,
  ...rest
}: ButtonProps) {
  const targetedIcon = icon && icons[icon];

  return (
    <button
      data-isIconLeft={String(isIconLeft)}
      onClick={() => {
        if (dataAnalytics) {
          plausibleTrack(dataAnalytics);
        }
      }}
      className={clsx(
        styles.button,
        {
          [styles.primary]: variant === "primary",
          [styles.outlined]: variant === "outlined",
          [styles.text]: variant === "text",
          [styles.withIcon]: !!icon,
        },
        className
      )}
      type={type}
      {...rest}
    >
      {isIconLeft ? (
        <>
          {targetedIcon}
          {children}
        </>
      ) : (
        <>
          {children}
          {targetedIcon}
        </>
      )}
    </button>
  );
}
