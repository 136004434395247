import Button from "@/components/elements/Button";
import LinkComponent from "@/components/elements/LinkComponent";
import Icon from "@/components/elements/Icon";
import Heading from "@/components/elements/Heading";
import checkMark from "@/assets/svgs/check-mark.svg";

import * as styles from "./styles.module.scss";

export function ThankYouSection({ name }: { name: string | null }) {
  return (
    <section className={styles.wrapper}>
      <div className="container">
        <div className={styles.successContainer}>
          <div className={styles.successContent}>
            <Heading level={1}>Thank You {name}!</Heading>
            <p>
              You have successfully subscribed to our quarterly newsletter. You
              will receive a confirmation e-mail shortly.
            </p>
            <Button variant="primary">
              <LinkComponent
                title="Go back to homepage"
                url="/"
                type="internal"
              />
            </Button>
          </div>
          <div className={styles.successCheckWrapper}>
            <div className={styles.successIcon}>
              <img src={checkMark} alt="Check Mark" />
            </div>
          </div>
        </div>
      </div>
      <Icon name="cocoa" className={styles.bgPattern} />
    </section>
  );
}
