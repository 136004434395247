import { useLocation } from "@reach/router";
import { URL_SEARCH_PARAMS } from "../helpers/consts";

export default function useQueryParams(param: URL_SEARCH_PARAMS) {
  if (typeof window === "undefined") {
    return null;
  }
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  return params.get(param);
}
