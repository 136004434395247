import Link from "@/components/elements/LinkComponent";

import LinkComponent from "@/components/elements/LinkComponent";
import * as styles from "./styles.module.scss";

export function SimplifiedFooter({
  logo,
  copyrights_menu,
  copyrights_title,
  language_code,
}: FooterTemplate) {
  return (
    <footer className={styles.footerWrapper}>
      <div className="container">
        <div className={styles.footerContent}>
          {logo && (
            <LinkComponent
              url={`/${language_code}`}
              type="internal"
              className={styles.logo}
            >
              <img src={logo?.filename} alt={logo?.alt} />
            </LinkComponent>
          )}
          <div className={styles.footerCopyright}>
            {Array.isArray(copyrights_menu) && (
              <ul>
                {copyrights_menu.map((link) => (
                  <li key={link.title}>
                    <Link type={link.type} url={link.url}>
                      {link.title}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
            {copyrights_title && <p>{copyrights_title}</p>}
          </div>
        </div>
      </div>
    </footer>
  );
}
