import LinkComponent from "@/components/elements/LinkComponent";
import * as styles from "./styles.module.scss";

export function SimplifiedHeader({
  logo,
  language_code,
}: {
  logo: StoryblokAsset;
  language_code: string;
}) {
  return (
    <header className={styles.menuWrapper}>
      <div className="container">
        <LinkComponent
          url={`/${language_code}`}
          type="internal"
          className={styles.logo}
        >
          <img src={logo?.filename} alt={logo?.alt} />
        </LinkComponent>
      </div>
    </header>
  );
}
